import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {

  }

  loging(data) {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/log`, data).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
}
