import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import JSEncrypt from 'jsencrypt';
import { environment } from 'src/environments/environment';
import crypto from 'crypto-js';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    console.log(request.url);
    console.log(environment.apiUrl);
    if (request.url.indexOf(environment.apiUrl) != -1) {
      if (currentUser && currentUser.token) {

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        });
      }

      var sign = new JSEncrypt();
      const current = new Date();
      let timestamps = current.getTime();
      sign.setPrivateKey(environment.privatekey);
      var signature = sign.sign(timestamps + JSON.stringify(request.body), crypto.SHA256, "sha256");
      request = request.clone({
        headers: request.headers.set('timestamp', timestamps.toString())
          .set('Content-Signature', `digest-alg=RSA-SHA; key-id=KEY:RSA:rsf.org; data=${signature}`),
      });
    }
    return next.handle(request);
  }
}
