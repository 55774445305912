import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {

    return this.currentUserSubject.value;
  }
  getTokenExpirationDate(token: string): Date {
    let decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.currentUserValue.token;
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);

    if (date === undefined) return false;

    return (date.valueOf() > new Date().valueOf());
  }
  getAccount() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiUrl}/myaccount`).toPromise()
        .then(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.user));

          this.currentUserSubject.next(res.user);

          resolve(res);
        }, err => reject(err));
    });
  }
  getAccountLine(lineid) {
  
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiUrl}/myaccountwithline?uid=${lineid}`).toPromise()
        .then(res => {
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          this.currentUserSubject.next(res.user);
          resolve(res);
        }, err => reject(err));
    });
  }
  getMyProfile() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiUrl}/auth/me`).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  login(authData) {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/auth`, authData).toPromise()
        .then(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('requestResetUser', res.request_reset);
          this.currentUserSubject.next(res.user);

          resolve(res);
        }, err => reject(err));
    });

    //     return this.http
    //       .post<any>(`${environment.apiUrl}/auth`, authData)
    //       .pipe(
    //         map((user) => {
    //           // store user details and jwt token in local storage to keep user logged in between page refreshes
    // console.log(user);
    //           // localStorage.setItem('currentUser', JSON.stringify(user));
    //           // this.currentUserSubject.next(user);
    //           return user;
    //         })
    //       );

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
  saveEditSkill(skill){
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/member/changeskill`, {Skills:skill}).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  saveChangeUP(info){
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/member/changeup`, info).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  forgetPassword(data){
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/auth/forgetpassword`, data).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  saveResetPassword(data){
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/member/resetPassword`, data).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  saveChangeInfo(){}
}
