import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import Swal from 'sweetalert2';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.authService.currentUserValue) {
      let isExp = this.authService.isTokenExpired();
      if (!isExp) {
        Swal.fire({
          icon: "info",
          title: 'Session หมดอายุ',
          text: 'กรุณา เข้าสู่ระบใหม่',
          allowOutsideClick: false
        }).then(result => {
          this.router.navigate(['/authentication/signin'], { queryParams: { returnUrl: state.url } });
        })
      }
      return true;
    }

    this.router.navigate(['/authentication/signin']);
    return false;
  }
}
