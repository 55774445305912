// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:8080/api/v1',
  apiUrl: 'https://devmis.in.ngrok.io/api/v1',
  currentUser:'currentUser',
  privatekey:`-----BEGIN RSA PRIVATE KEY-----
  MIIEpAIBAAKCAQEAolOet8fN4LYSI43LPIMd1tAvNoatokYOYemSDR0/kX/0Mp+W
  jlcSMGcpqpw4UeP80rdnqn9+lKvzCvwNfXehm9NzlwkLKqK8mz3KaXibaCsjr5K1
  KQhVM2QmDRNsNAfJus3YWrmbY9H6QsAky8uBSsMpDNWMzGyBK4CVBxF5sm6jori/
  txLfcD2OEK+QYuYCIxX+HDBlu/IHt6gZLdQIehSAKSpPHPYcX+b5hJTFCPthnG8I
  TC4O6S9fJhsez+nBVORWEWDXNUTLYm4113Zdx0somxxfkpDdG7vLUOCehua30Tv3
  rHHfdrE98j7LYKIIod8OSOJt68qTSm37CSaDcwIDAQABAoIBAARK28aWVP5dMiuY
  g0hNW0uj46u4cfz5h0XVjFQmctjhHk78Y1nOOZ/oCnUFfoPD2Jql2OrsRieeSGYA
  9kZcFHzvYX4b8hylgwFD6AdT9iASC/aHlZzJKJ+cRVlRKypRDmePK+pyQAaqtchw
  FkkBT6DvejVprDh3og7JbqdqMeKi+Yt4+TrHROJdOFx1JDPpeuK7nLB5sTnhzX8J
  2gzRz1fognjhJzHqElAQGfxiTf8U/9JhuaKmqquDFdvuobCjC7WfT7EC594fOsJp
  fzvGcwOBlTOdkw4eRMukfHJ/urCnIaI5qBbERRIBp1osnHUi5zyWrKX1687CJ9Ay
  bYKvg8kCgYEA1htH3C8mVh+D9tbhFpV5NkckQAQGB460cEKThFTTPIx1OCEItc2s
  iWGP5+lLc2gOvbTp8OB/qx4fxNy+p/hrgcjQ1xzR8ZQbBUZbauCfyGpAacZAL0Y4
  elnL86vNXGZuYLGrC5/35Mm7GIuO7VvzTkf0QqNjVwhhC4gTWAV12B8CgYEAwhan
  S0R9Wj8hep2XbRcvOgcBVq1OmoJ4n8DPSY8EBbqIVBZeJ0JldbmWuqzSfsYmj5w/
  ztWNZpLQxDyVsB4kh9TmPz1xqomDdTYund6C22O/vxj+ELs8VOKKo/HdKiO/DmRc
  b09XSfX57xC4C9lY2NtVajRn7nXPE+TilUJ3ui0CgYEAkwXau6UY8yzjBn6ZxFNS
  D+acSuU4arogx7G/8K/6+eoNmER+4bDhWNlI41x9NjtvW+++cW0H3i5qbFmVBmz9
  DfsjBYRB2lz0jRO6NNMYhZwIrVm4u33BzKqa9bG07E1eYEHAjdQEevPX6Uv0EqQA
  mHGqlpPx5FLZqPyBJAFj4csCgYAoikTNYvPv+snKa8pqXfJeY4IzEaYefewmKkbh
  7X6Al9LMBZQLDUJWEbrbKSgalNcPHirx0AdmCnqS0rjNfa5RtPWW9gWdAnYz8GpS
  TVz16GLvzo+a/REABgXBcs6oc6j/n5TzJxZre3aGKe7XgsGdIks4ENQ4qeDkHeX6
  GVUazQKBgQDGxUt7p5pfmyp7ccc9B1SKyVF1Ee2d3EL4aLYr0EBfW0jtktnhol0R
  8ZrrXf5lWcrqCKvSXvMXF66gWJNpbOcFAJr3hgU3/VHi6FAk9vGbVGANicSo9hab
  +Znnl8oltu4N6pkYml0aOD86eCJ4VLmdM36ePjIqfz9OkSJEfHjrag==
  -----END RSA PRIVATE KEY-----`,
   line_client_id:'1656548027',
  line_client_secret:'51398e94c0bf4bcde6985bafc627f715'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
