import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemService {


  constructor(private http: HttpClient) {

  }


  getFormData() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiUrl}/formData`).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  checkExistData(data){
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/auth/existdata`,data).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  getPolicy() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiUrl}/auth/policy`).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  saveRegis(data) {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/auth/register`,data).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }
  getAllMemberInBlanch(){
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiUrl}/allmember`).toPromise()
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });

  }
}
