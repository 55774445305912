<section class="content">
  <div class="container-fluid">
    <!-- Basic Examples -->
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">รายการย้อนหลัง</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a [routerLink]="['/dashboard/main']" routerLinkActive="router-link-active" onClick="return false;">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item active">รายการย้อนหลัง</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="body">
            <div class="row">
              <div class="col-4 offset-md-4 text-center">
                <div  class="profile_photo" style="background-image: url('{{lineAccount.pictureUrl}}');"></div>
                <br><div>{{lineAccount.displayName}}</div>
                <div class='text-success'><i class='la la-check'></i> เชื่อมต่อบัญชี Line เรียบร้อย</div>
                <hr>
                <div class='btn-group'>
                  <a href='/dashboard/main'>กลับหน้าแรก</a>
                </div>
              </div>
            </div>
           <!-- {{lineAccount | json}} -->
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
