import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../core/service/auth.service';
import pat from '../../assets/data/pat.json';
import { SystemService } from '../core/service/system.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  account: any;
  profile: any = {
    account: {},
    data: {},
    state: {}
  };
  submitted = false;
  submittedUP = false
  mskills = [];
  mskillsTemp = [];
  memberDataForm: FormGroup
  accountAuth: FormGroup;
  dataProvince = [];
  dataAmp = [];
  dataDistrict = [];
  formData = {
    Branch: [],
    Prefix: [],
    Race: [],
    Cult: [],
    Educations: [],
    Career: [],
    aptitude: [],

  };
  Skills = [];
  birth = {
    days: [],
    months: [
      { id: '01', name: 'ม.ค.' },
      { id: '02', name: 'ก.พ.' },
      { id: '03', name: 'มี.ค.' },
      { id: '04', name: 'เม.ย.' },
      { id: '05', name: 'พ.ค.' },
      { id: '06', name: 'มิ.ย.' },
      { id: '07', name: 'ก.ค.' },
      { id: '08', name: 'ส.ค.' },
      { id: '09', name: 'ก.ย.' },
      { id: '10', name: 'ต.ค.' },
      { id: '11', name: 'พ.ย.' },
      { id: '12', name: 'ธ.ค.' }
    ],
    years: []
  }
  filePath = '';
  hasDragOver = true;
  allowEdit = false;

  constructor(private auth: AuthService,
    private formBuilder: FormBuilder,
    private systemService: SystemService,
    private modal: NgbModal) {
    this.systemService.getFormData().then(resp => {
      console.log(resp);
      this.formData = resp;
      this.Skills = resp.aptitude;
    })
    for (let d = 1; d <= 31; d++) {
      this.birth.days.push(d);
    }
  }

  ngOnInit() {
    this.dataProvince = pat.provinces;
    this.account = this.auth.currentUserValue;
    this.getProfile();
    this.userpasswordForm()
    this.accInfoForm();
  }
  accInfoForm(){
    this.memberDataForm = this.formBuilder.group({
      image: [, Validators.required],
      branch: ['', Validators.required],
      iden: ['', [Validators.required, Validators.maxLength(13), Validators.minLength(13)]],
      preface: ["", Validators.required],
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      nickname: ['', Validators.required],
      birthday: ['', Validators.required],
      birthday_date: ['', Validators.required],
      birthday_month: ['', Validators.required],
      birthday_year: ['', Validators.required],
      age: [],
      nationality: ['ไทย'],
      extraction: ['ไทน'],
      cult: [''],
      identitiy_id: ['1'],
      education_id: [''],
      vocation_id: [''],
      weight: [],
      height: [],
      telephone_home: [],
      telephone: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      Line_ID: [],
      village: [],
      houseno: ['', Validators.required],
      moo: [],
      road: [],
      alley: [],
      subdistrict: [null, Validators.required],
      district: [null, Validators.required],
      province: [null, Validators.required],
      zip: ['', Validators.required],
    });
    for (var control in this.memberDataForm.controls) {
      this.memberDataForm.controls[control].disable();
  }
  }
  userpasswordForm() {
    console.log("u::" + this.profile.data.username)
    this.accountAuth = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      newpassword: ['', [Validators.required, Validators.minLength(6)]],
      cnewpassword: ['', [Validators.required]]
    }, {
      validator: [ConfirmedValidator2('newpassword', 'cnewpassword'),SamOldpassword('password', 'newpassword')]
    });
  }
  get t() {
    return this.memberDataForm.controls;
  }
  get uform() {
    return this.accountAuth.controls;
  }
  getProfile() {
    this.auth.getMyProfile().then(data => {
      this.profile = data;
      this.mskillsTemp = data.skills;
      data.skills.forEach(element => {
        let ind = this.mskills.findIndex(i => i.eventid == element.eventid);
        if (ind != -1) {
          this.mskills[ind].item.push(element)
        } else {
          element.item = [element];
          this.mskills.push(element)
        }
      });
      this.accountAuth.patchValue({
        username: this.profile.data.username
      });
      let brd = this.profile.data.birthday.toString().split('-');
      this.memberDataForm.patchValue({
        iden:this.profile.data.iden,
        preface:this.profile.data.preface,
        name:this.profile.data.name,
        lastname:this.profile.data.lastname,
        nickname:this.profile.data.nickname,
        birthday: this.profile.data.birthday,
        birthday_date:brd[2],
        birthday_month:brd[1],
        birthday_year:brd[0],
        age: this.profile.data.age,
        nationality: this.profile.data.nationality,
        extraction:this.profile.data.extraction,
        cult: this.profile.data.cult,
        identitiy_id:this.profile.data.identitiy_id,
        education_id:this.profile.data.education_id,
        vocation_id: this.profile.data.vocation_id,
        weight: this.profile.data.weight,
        height: this.profile.data.height,
        telephone_home:this.profile.data.telephone_home,
        telephone:this.profile.data.telephone,
        email:this.profile.data.email,
        Line_ID: this.profile.data.Line_ID,
        village: this.profile.data.village,
        houseno: this.profile.data.houseno,
        moo: this.profile.data.moo,
        road: this.profile.data.road,
        alley: this.profile.data.alley,
        subdistrict: this.profile.data.subdistrict,
        district: this.profile.data.district,
        province: this.profile.data.province,
        zip: this.profile.data.zip,
      })
    })
  }
  editDataInfo() {
    this.allowEdit = !this.allowEdit;
  }
  imagePreview(e) {




    const file = (e.target as HTMLInputElement).files[0];

    // this.memberDataForm.patchValue({
    //   image: file
    // });

    this.memberDataForm.get('image').updateValueAndValidity()

    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    }
    reader.readAsDataURL(file)
  }
  checkYear(event) {
    let cdate = new Date();
    if (((cdate.getFullYear() + 543) - event.target.value) > 100) {
      Swal.fire({
        icon: "info",
        text: "ปี พ.ศ. ไม่ถูกต้อง"
      });
      event.target.value = ""
    }
  }
  onSelectProvince(event) {
    this.dataAmp = [];
    if (event) {
      this.dataAmp = pat.amp.filter(item => item.province_id == event.id);
    }
  }
  onSelectAmp(event) {
    this.dataDistrict = [];
    if (event) {
      this.dataDistrict = pat.district.filter(item => item.amphure_id == event.id);
    }
  }
  onSelectDistrict(event) {
    this.memberDataForm.patchValue({
      'zip': event.zip_code
    })
  }
  checkExist(column, data, dataname) {
    if (data.target.value.toString().length > 0) {
      let dataCheck = {
        column: column,
        data: data.target.value
      }
      this.systemService.checkExistData(dataCheck).then(resp => {
        if (resp.id) {
          Swal.fire({
            icon: "warning",
            title: dataname + ' พบมีการลงทะเบียนแล้ว ไม่สามารถใช้ได้'
          })
          // data.target.focus();
          data.target.value = '';

        }
      });
    }
  }
  // calHI(min) {
  //   let dd = {
  //     HH: 0,
  //     MM: 0
  //   }
  //   dd.HH = (min / 60);
  //   dd.MM = (min % 60);
  //   return ('0' + dd.HH).slice(-2) + ":" + ('0' + dd.MM).slice(-2);
  // }
  calHI(min) {
    let dd = {
      HH: 0,
      MM: 0
    }
    dd.HH = Math.floor(min / 60);
    dd.MM = Math.floor(min % 60);
    return ('0' + dd.HH).slice(-2) + ":" + ('0' + dd.MM).slice(-2);
  }
  submitForm1() { }
  //Skill
  editSkill(editSkillDialog) {

    this.Skills.forEach(elm => {
      elm.items.forEach(itm => {
        let idn = this.mskillsTemp.findIndex(i => i.subid == itm.id);
        if (idn != -1) {
          itm.selected = true;
        }
      });
    })

    this.modal.open(editSkillDialog, { size: 'xl' });
  }
  saveSkill() {
    let dataSkill = [];
    this.Skills.forEach(elm => {
      elm.items.forEach(itm => {
        if (itm.selected && itm.optional == '1' && itm.optionaltitle == '') {
          alert("กรุณาระบทักษะอื่นๆ");
          return;
        }
        if (itm.selected) {
          dataSkill.push(itm);
        }
      });
    });
    this.auth.saveEditSkill(dataSkill).then(resp => {
      Swal.fire({
        icon: 'success',
        text: "แก้ไขข้อมูลเรียบร้อย"
      });
      this.mskills = [];
      this.mskillsTemp = resp.data;
      resp.data.forEach(element => {
        let ind = this.mskills.findIndex(i => i.eventid == element.eventid);
        if (ind != -1) {
          this.mskills[ind].item.push(element)
        } else {
          element.item = [element];
          this.mskills.push(element)
        }
      });
      this.modal.dismissAll();
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        text: "แก้ไขข้อมูลไม่เรียบร้อย!"
      })
    })
  }
  //UserName/Password
  checkOldPassword(event) {
    
  }
  submitChangeUP() {
    this.submittedUP = true;
    if (this.accountAuth.invalid) {
      return;
    }
    this.auth.saveChangeUP(this.accountAuth.getRawValue()).then(resp => {

      Swal.fire({
        icon: 'success',
        text: "แก้ไขข้อมูลเรียบร้อย"
      });
      this.profile.data.username = resp.username;
      this.accountAuth.patchValue({
        username: this.profile.data.username
      });
      this.accountAuth.patchValue({
        password: '',
        newpassword: '',
        cnewpassword: ''
      });
      localStorage.setItem('requestResetUser','false');
      this.submittedUP=false;
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        text: err
      })
      this.accountAuth.patchValue({
        password: '',
        newpassword: '',
        cnewpassword: ''
      });
    })
  }
}
export function ConfirmedValidator2(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
export function SamOldpassword(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value === matchingControl.value) {
      matchingControl.setErrors({ sameOldPassword: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}