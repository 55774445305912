<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul id="sidebarnav" class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel" (click)="editPhotoDialog(editPhoto)">
            <div class=" image">
              <div class='profile_photo'
              style='background-image: url({{accInfo.PersonImg}});'
              ></div>
              <!-- <img src="{{accInfo.PersonImg}}" class="img-circle user-img-circle" alt="User Image" /> -->
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name"> {{accInfo.displayName}}</div>
            <!-- <div class="profile-usertitle-job ">{{'MENUITEMS.USER.POST' | translate}} </div> -->
          </div>
        </li>
        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
            [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
            <i [ngClass]="[sidebarItem.icon]"></i>
            <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
          </a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                {{sidebarSubItem.title | translate}}
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title | translate}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
<ng-template #editPhoto let-c="close" let-d="dismiss" id="printMe">
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel2"><i class="la la-pencil-square-o"></i>แก้ไขภาพโปรไฟล์
    </h4>

  </div>
  <div class="modal-body">
    <input type="file" (change)="fileChangeEvent($event)" />

    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 3"
      format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [style.display]="showCropper ? null : 'none'">
    </image-cropper>

    <img [src]="croppedImage" />

    <!-- ////////////////////////////////////////////////////////////////////////////-->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
    <button type="button" class="btn btn-outline-primary">บันทึก</button>
  </div>
</ng-template>
