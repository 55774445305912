import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { DatePipe } from '@angular/common';
import { AccountService } from '../core/service/account.service';
import { AuthService } from '../core/service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// declare const M: any;
interface Gender {
  id: string;
  value: string;
}
@Component({
  selector: 'app-lineconnect',
  templateUrl: './lineconnect.component.html',
  styleUrls: ['./lineconnect.component.css']
})
export class LineConnectComponent implements OnInit {
  lineAccount: any={
    pictureUrl:"",
    displayName:""
  };
  constructor(
    private actParam: ActivatedRoute,
    private _http: HttpClient
  ) {
    let line = atob(this.actParam.snapshot.params.uid);
    console.log(this.actParam.snapshot.params.uid)
    console.log(line)
    let req = this._http.get<any>("https://api.line.me/v2/profile",
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+line
        })
      })

      .subscribe(response => {
        this.lineAccount = response;
      })
  }
  ngOnInit() {

  }

}
