<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Profile</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/dashboard/main">
                <i class="fas fa-home"></i> Home</a>
            </li>

            <li class="breadcrumb-item active">Profile</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Your content goes here  -->
    <div class="row clearfix">
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="m-b-20">
            <div class="contact-grid">
              <div class="profile-header bg-dark">
                <div class="user-name">{{account.displayName}}</div>
                <!-- <div class="name-center">Software Engineer</div> -->
              </div>
              <!-- <img src="{{account.PersonImg}}" class="user-img" alt=""> -->
              <div class="user-panel" style="margin-top: -90px;">
                <div class=" image">
                  <div class='profile_photo' style='background-image: url({{account.PersonImg}});'></div>
                  <!-- <img src="{{accInfo.PersonImg}}" class="img-circle user-img-circle" alt="User Image" /> -->
                </div>
              </div>
              <p>
                {{profile.data.houseno}} หมู่ที่ {{profile.data.moo}} ซ. {{profile.data.moo}} ถ. {{profile.data.moo}}
                <br>ตำบล/แขวง {{profile.data.subdistrict}} อำเภอ/เขต {{profile.data.district}}<br> จ.
                {{profile.data.province}} {{profile.data.zip}}


              </p>
              <div>
                <span class="phone">
                  <i class="material-icons">phone</i>{{profile.data.telephone}}</span>
              </div>
              <div class="row">
                <div class="col-6">
                  <h2><strong>{{profile.state.RequestAll}}</strong></h2>
                  <small>ขอความช่วยเหลือ</small>
                </div>
                <div class="col-6">
                  <h2><strong>{{profile.state.MapAll}}</strong></h2>
                  <small>ให้ความช่วยเหลือ</small>
                </div>
                <!-- <div class="col-4">
                  <h5>565</h5>
                  <small>Post</small>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <mat-tab-group mat-align-tabs="start">

            <mat-tab label="ทักษะ">
              <ul class="list-unstyled p-4">
                <li *ngFor="let item of mskills">
                  <div>[{{item.eventname}}]</div>
                  <div *ngFor="let si of item.item" style='padding-left: 15px;;'> - {{si.subname}}</div>
                  <!-- <div class="progress skill-progress m-b-20">
                    <div class="progress-bar l-bg-green width-per-45" role="progressbar" aria-valuenow="45"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div> -->
                </li>
                <li class='text-center'>
                  <hr>
                  <button class='btn btn-info' (click)="editSkill(editSkillDialog)">แก้ไขทักษะ</button></li>
              </ul>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <mat-tab-group>

            <mat-tab>
              <ng-template mat-tab-label>
                <i class="las la-user-circle" style='font-size: 2rem;'></i>
                ข้อมูลส่วนบุคคล
              </ng-template>
              <div class="header">
                <h2>
                  <strong>ข้อมูลเข้าใช้งาน</strong></h2>
              </div>
              <div class="body">
                <form [formGroup]="accountAuth" class='form form-horizontal' (ngSubmit)="submitChangeUP()">
                  <div class="form-body">
                    <div class="row">
                      <div class="col-md-12" style='margin-bottom: 0px;'>
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">ชื่อผู้ใช้<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="username" />
                            <small class="form-text text-danger" *ngIf="submittedUP && uform.username.errors">
                              <div *ngIf="uform.username.errors.required">
                                ห้ามเว้นว่าง
                              </div>

                            </small>
                          </div>

                        </div>
                      </div>


                    </div>

                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12" style='margin-bottom: 0px;'>
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">รหัสผ่านเดิม<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="password" formControlName="password" />
                            <small class="form-text text-danger" *ngIf="submittedUP && uform.password.errors">
                              <div *ngIf=" uform.password.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                              <div *ngIf="uform.password.errors.minlength">
                                ต้องมีอย่างน้อย 6 ตัวอักษร
                              </div>
                            </small>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12" style='margin-bottom: 0px;'>
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">รหัสผ่านใหม่<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="password" formControlName="newpassword" (blur)='checkOldPassword($event)'/>
                            <small class="form-text text-danger" *ngIf="submittedUP && uform.newpassword.errors">
                              <div *ngIf=" uform.newpassword.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                              <div *ngIf=" uform.newpassword.errors.minlength">
                                ต้องมีอย่างน้อย 6 ตัวอักษร
                              </div>
                              <div *ngIf="uform.newpassword.errors.sameOldPassword">
                                ไม่สามารถใช้รหัสผ่านเดิมได้
                              </div>
                            </small>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">ยืนยันรหัสผ่านใหม่<span
                              class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="password" formControlName="cnewpassword" />
                            <small class="form-text text-danger" *ngIf="submittedUP && uform.cnewpassword.errors">
                              <div *ngIf="uform.cnewpassword.errors.confirmedValidator">
                                รหัสผ่านไม่ตรงกัน
                              </div>

                            </small>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="text-center"> <button type='submit' class='btn btn-primary'>บันทึก</button> </div>

                </form>
                <!-- <div class="example-container">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>ชื่อผู้ใช้</mat-label>
                    <input matInput [(ngModel)]="profile.data.username">
                  </mat-form-field>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>รหัสผ่านปัจจุบัน</mat-label>
                    <input matInput type="password">
                  </mat-form-field>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>รหัสผ่านใหม่</mat-label>
                    <input matInput type="password">
                  </mat-form-field>
                </div>
                <button mat-raised-button color="primary">บันทึก</button> -->
              </div>
              <div class="header">
                <h2 style="cursor: pointer;" (click)='editDataInfo()'>
                  <strong>แก้ไขข้อมูลส่วนบุคคล</strong></h2>
              </div>
              <div class="body" *ngIf="allowEdit">
                <form [formGroup]="memberDataForm" (ngSubmit)="submitForm1()" class='form form-horizontal'>



                  <div class="form-body">
                    <div class="row">
                      <div class="col-md-12" style='margin-bottom: 0px;'>
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control" for="userinput1">เลขบัตรประชาชน<span
                              class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input type='text'
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              class='form-control' formControlName="iden"
                              (blur)="checkExist('iden',$event,'เลขบัตรประชาชน')" maxlength="13">
                            <small class="form-text text-danger" *ngIf="submitted && t.iden.errors">
                              <div *ngIf=" t.iden.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                              <div *ngIf="t.iden.errors.minlength || t.iden.errors.maxlength">
                                ต้องมี 13 หลัก
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12" style='margin-bottom: 0px;'>
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">คำนำหน้า<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <select class="form-control input-md" formControlName="preface">
                              <option value="">เลือก</option>
                              <option *ngFor="let item of formData?.Prefix" [value]="item.id">
                                {{item.name}}
                              </option>
                            </select>
                            <small class="form-text text-danger" *ngIf="submitted && t.preface.errors">
                              <div *ngIf=" t.preface.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-12" style='margin-bottom: 0px;'>
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">ชื่อ<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="name" />
                            <small class="form-text text-danger" *ngIf="submitted && t.name.errors">
                              <div *ngIf=" t.name.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" style='margin-bottom: 0px;'>
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">นามสกุล<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="lastname" />
                            <small class="form-text text-danger" *ngIf="submitted && t.lastname.errors">
                              <div *ngIf=" t.lastname.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">ชื่อเล่น<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="nickname" />
                            <small class="form-text text-danger" *ngIf="submitted && t.nickname.errors">
                              <div *ngIf=" t.nickname.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">วันเกิด<span class='text-danger'>*</span></label>
                          <div class="col-md-8 row">
                            <div class="col-md-4">
                              <select class="form-control" formControlName="birthday_date">
                                <option value=''>วัน</option>
                                <option *ngFor="let day of birth.days" value="{{day}}">{{day}}
                                </option>
                              </select>
                              <small class="form-text text-danger" *ngIf="submitted && t.birthday_date.errors">
                                <div *ngIf=" t.birthday_date.errors.required">
                                  ห้ามเว้นว่าง
                                </div>
                              </small>
                            </div>
                            <div class="col-md-4">
                              <select class="form-control" formControlName="birthday_month">
                                <option value=''>เดือน</option>
                                <option *ngFor="let m of birth.months" value="{{m.id}}">
                                  {{m.name}}</option>
                              </select>
                              <small class="form-text text-danger" *ngIf="submitted && t.birthday_month.errors">
                                <div *ngIf=" t.birthday_month.errors.required">
                                  ห้ามเว้นว่าง
                                </div>
                              </small>
                            </div>
                            <div class="col-md-4">
                              <input class="form-control" type="text" formControlName="birthday_year"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                maxlength="4" placeholder="พ.ศ. เท่านั้น" (blur)="checkYear($event)" />
                              <small class="form-text text-danger" *ngIf="submitted && t.birthday_year.errors">
                                <div *ngIf=" t.birthday_year.errors.required">
                                  ห้ามเว้นว่าง
                                </div>
                              </small>
                            </div>
                          </div>


                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">สถานะสมรส</label>
                          <div class="col-md-8">
                            <select class='form-control' formControlName="identitiy_id">

                              <option value="1">โสด</option>
                              <option value="2">แต่งงาน</option>
                              <option value="3">หย่าร้าง</option>
                              <option value="4">แยกกันอยู่</option>
                              <option value="5">ไม่ระบุ</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">

                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">สัญชาติ</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="nationality"
                              placeholder="สัญชาติ" />

                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">เชื้อชาติ</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="extraction"
                              placeholder="เชื้อชาติ" />

                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control" >ศาสนา</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="cult" placeholder="ศาสนา" />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">การศึกษา</label>
                          <div class="col-md-8">
                            <select class='form-control' formControlName="education_id">
                              <option value="">กรุณาเลือก</option>
                              <option *ngFor="let b of formData.Educations" value="{{b.id}}">
                                {{b.name}}</option>
                            </select>

                          </div>

                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">อาชีพ</label>
                          <div class="col-md-8">
                            <select class='form-control' formControlName="vocation_id">
                              <option value="">กรุณาเลือก</option>
                              <option *ngFor="let b of formData.Career" value="{{b.id}}">
                                {{b.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">เบอร์โทรศัพท์</label>
                          <div class="col-md-8">
                            <input class="form-control" type="number" formControlName="telephone_home" />

                          </div>

                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">มือถือ<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              formControlName="telephone" maxlength="10"
                              (blur)="checkExist('telephone',$event,'เบอร์มือถือ')" />
                            <small class="form-text text-danger" *ngIf="submitted && t.telephone.errors">
                              <div *ngIf=" t.telephone.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                              <div *ngIf=" t.telephone.errors.minlength || t.telephone.errors.maxlength">
                                ต้องมี 10 หลัก
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">Line ID</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="Line_ID" />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">Email<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="email"
                              (blur)="checkExist('email',$event,'Email')" />
                            <small class="form-text text-danger" *ngIf="submitted && t.email.errors">
                              <div *ngIf="t.email.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                              <div *ngIf="t.email.errors.email">
                                Email ไม่ถูกต้อง
                              </div>
                            </small>
                          </div>

                        </div>
                      </div>


                    </div>
                  </div>

                  <h2 class="font-16"><strong>ข้อมูลที่อยู่อาศัย</strong></h2>
                  <hr>
                  <div class="form-body" style='margin-bottom: 20px;'>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">ชื่อหมู่บ้าน</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="village" />

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">เลขที่<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="houseno" />
                            <small class="form-text text-danger" *ngIf="submitted && t.houseno.errors">
                              <div *ngIf=" t.houseno.errors.required">
                                ห้ามเว้นว่าง
                              </div>

                            </small>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">หมู่ที่</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="moo" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">ซอย</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="alley" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">ถนน</label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="alley" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-body">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">
                          <label class="col-md-4 label-control">จังหวัด<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <ng-select [multiple]="false" [items]="dataProvince" formControlName="province"
                              [selectOnTab]="true" bindValue="id" bindLabel="nameTH" placeholder="เลือกจังหวัด"
                              (change)='onSelectProvince($event)'>
                            </ng-select>
                            <small class="form-text text-danger" *ngIf="submitted && t.province.errors">
                              <div *ngIf=" t.province.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">อำเภอ/เขต<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <ng-select [multiple]="false" [items]="dataAmp" formControlName="district"
                              [selectOnTab]="true" bindValue="id" bindLabel="name_th" placeholder="เลือกอำเภอ/เขต"
                              (change)='onSelectAmp($event)'>
                            </ng-select>
                            <small class="form-text text-danger" *ngIf="submitted && t.district.errors">
                              <div *ngIf=" t.district.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">ตำบล/แขวง<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <ng-select [multiple]="false" [items]="dataDistrict" formControlName="subdistrict"
                              [selectOnTab]="true" bindValue="id" bindLabel="name_th" placeholder="เลือกตำบล/แขวง"
                              (change)='onSelectDistrict($event)'>
                            </ng-select>
                            <small class="form-text text-danger" *ngIf="submitted && t.district.errors">
                              <div *ngIf=" t.district.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group row mx-auto">

                          <label class="col-md-4 label-control">รหัสไปรษณีย์<span class='text-danger'>*</span></label>
                          <div class="col-md-8">
                            <input class="form-control" type="text" formControlName="zip" />
                            <small class="form-text text-danger" *ngIf="submitted && t.zip.errors">
                              <div *ngIf=" t.zip.errors.required">
                                ห้ามเว้นว่าง
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group text-center">
                    <button type="submit" class="btn btn-info btn-raised" awNextStep>
                      <!-- [disabled]="!memberDataForm.valid" -->
                      บันทึกข้อแก้ไข
                      <span style="margin-left:10px;">
                        <i class="feather ft-chevron-right"></i>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <i class="las la-address-card" style='font-size: 2rem;'></i>
                ข้อมูลบัญชี
              </ng-template>
              <div class="header">
                <h2>
                  <strong>ข้อมูลบัญชี</strong></h2>
              </div>
              <div class="body book">
                <div class='card' *ngFor="let acc of profile.account">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        เลขบัญชี : <span class='acc-data'>{{acc.acc_no}}</span><br>
                        ชื่อบัญชี : <span class='acc-data'>{{acc.acc_name}}</span><br>
                        สาขา<br>
                      </div>
                      <div class="col-6">
                        คงเหลือ(ชั่วโมง:นาที)<br>
                        <span class='font-32'>
                          <span class='acc-data'>{{calHI(acc.balance)}}</span>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #editSkillDialog let-c="close" let-d="dismiss">
  <div class="modal-header text-center">
    <h4 class="modal-title" id="myModalLabel2">
      แก้ไขทักษะ
    </h4>
  </div>
  <div class="modal-body font-20 " style='background-color: #eee;'>
    <div class="subHeaderTitle">กรุณาเลือกทักษะความสามารถของท่าน
      โดยสามารถเลือกได้มากกว่า 1 รายการ</div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mb-2" *ngFor="let item of Skills">
        <div class="blockExpert">
          <div class="card my-3 mx-3">
            <div class="card-header" style="background-color: #fff;">
              <label class="checkbox" style=" color: #fff;font-weight: bold;">
                <!-- <input type="checkbox" class="checkAll" id="checkAll_1" /> -->
                <h2 class="font-14"><strong>{{item.name}} </strong></h2>
              </label>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let itm of item.items">
                <label class="checkbox font-16">
                  <input type="checkbox" class="checkAll_1 checkboxSub" [(ngModel)]="itm.selected"  />
                  {{itm.name}} </label>
                  <input class='form-control' [ngClass]="{'ng-invalid': itm.selected && itm.optional=='1' && itm.optionaltitle==''}" [(ngModel)]="itm.optionaltitle" *ngIf="itm.selected && itm.optional=='1'" placeholder="ระบุ" required>
                  <small *ngIf="itm.selected && itm.optional=='1' && itm.optionaltitle==''" class='text-danger'>ห้ามเว้นว่าง</small>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer ">
    <button type="button" class="btn btn-danger" (click)="d('close')">ปิด</button>
    <button type="button" class="btn btn-success" (click)="saveSkill()">บันทึก</button>
  </div>
</ng-template>
